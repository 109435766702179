
import { defineComponent, ref, onMounted, onBeforeUnmount, computed, inject } from 'vue';
import CrmCustomerEditorModal from '@/components/CrmCustomers/CrmCustomerEditorModal.vue';
import CrmCustomersFilterModal from '@/components/CrmCustomers/CrmCustomersFilterModal.vue';
import CrmCustomerPageHeader from '@/components/CrmCustomers/CrmCustomerPageHeader.vue';
import { CrmCustomerListModel, CrmCustomerListFilter } from '@/models/CrmCustomers';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import router from '@/router';
import { generalStore } from '@/store';
import CrmCustomerChatMessagePopup from '@/components/CrmCustomers/CrmCustomerChatMessagePopup.vue';

export default defineComponent({
    name: 'CrmCustomers',
    components: { CrmCustomerEditorModal, CrmCustomersFilterModal, CrmCustomerPageHeader, CrmCustomerChatMessagePopup },
    async setup() {
        const emitter: any = inject('emitter');
        const loaded = ref(false);
        const busy = ref(false);
        const hasNext = ref(false);
        const modalCrmCustomerEditor = ref<InstanceType<typeof CrmCustomerEditorModal>>();
        const modalCrmCustomersFilter = ref<InstanceType<typeof CrmCustomersFilterModal>>();
        const modalCrmCustomerChatMessage = ref<InstanceType<typeof CrmCustomerChatMessagePopup>>();

        const localCurrencySymbol = generalStore.state.localCurrencySymbol;
        const crmCustomers = ref<CrmCustomerListModel[]>([]);
        const filter = ref(new CrmCustomerListFilter());
        const total = ref(0);
        const totalFiltered = ref(0);

        function back() {
            router.go(-1);
        }

        function ShowFilter() {
            modalCrmCustomersFilter.value?.open(filter.value);
        }

        const hasFilter = computed<boolean>(() => {
            if (filter.value.businessClientId) {
                return true;
            }
            if (filter.value.createDateFrom) {
                return true;
            }
            if (filter.value.createDateTo) {
                return true;
            }
            if (filter.value.lastOrderDateFrom) {
                return true;
            }
            if (filter.value.lastOrderDateTo) {
                return true;
            }
            return false;
        });

        const GetCrmCustomersApiCall = async () => {
            swal.fire({
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    swal.showLoading();
                },
                showConfirmButton: false
            });
            busy.value = true;
            const apiResult = await api.getCrmCustomerList(filter.value);
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            hasNext.value = apiResult.data?.hasNext || false;
            if (filter.value.pageIndex == 0) {
                total.value = apiResult.data?.total || 0;
                totalFiltered.value = apiResult.data?.totalFiltered || 0;
            }
            if (apiResult.data && crmCustomers.value && crmCustomers.value.length) {
                crmCustomers.value = [...crmCustomers.value, ...apiResult.data.crmCustomers];
            } else {
                crmCustomers.value = apiResult.data?.crmCustomers || [];
            }
            filter.value.pageIndex++;
            busy.value = false;
            swal.close();
        };

        async function applyFilter(filterObj: CrmCustomerListFilter) {
            filter.value = filterObj;
            crmCustomers.value = [];
            filter.value.pageIndex = 0;
            await GetCrmCustomersApiCall();
        }
        async function GetCrmCustomers() {
            crmCustomers.value = [];
            filter.value.pageIndex = 0;
            await GetCrmCustomersApiCall();
        }
        function CreateCustomer() {
            modalCrmCustomerEditor.value?.open();
        }
        async function showAll() {
            filter.value = new CrmCustomerListFilter();
            crmCustomers.value = [];
            await GetCrmCustomersApiCall();
        }
        async function sotrBy(sotrBy: string) {
            if (filter.value.orderBy != sotrBy) {
                filter.value.asc = true;
            } else {
                filter.value.asc = !filter.value.asc;
            }
            filter.value.orderBy = sotrBy;
            filter.value.pageIndex = 0;
            crmCustomers.value = [];
            await GetCrmCustomersApiCall();
        }
        function getArrowClass(sort: string) {
            if (sort == filter.value.orderBy) {
                if (filter.value.asc) {
                    return 'top-sort-arrow';
                } else {
                    return 'bottom-sort-arrow';
                }
            }
            return 'standart-sort-arrow';
        }
        function goToDetails(crmCustomerId: string) {
            router.push({ name: 'crm-customer-details', params: { id: crmCustomerId } });
        }
        function openCrmCustomerChatMessageModal(crmCustomerId: string, crmCustomerFullName: string) {
            modalCrmCustomerChatMessage.value?.open(crmCustomerId, crmCustomerFullName);
        }

        async function ListenerGetCrmCustomers() {
            if (hasNext.value && !busy.value) {
                await GetCrmCustomersApiCall();
            }
        }

        function onBeforeUnMountedCall() {
            emitter.off('isBottom', ListenerGetCrmCustomers);
        }

        const onMountedCall = async () => {
            emitter.on('isBottom', ListenerGetCrmCustomers);
            filter.value.pageIndex = 0;
            await GetCrmCustomersApiCall();
            loaded.value = true;
        };

        onMounted(onMountedCall);
        onBeforeUnmount(onBeforeUnMountedCall);
        return {
            modalCrmCustomerEditor,
            modalCrmCustomersFilter,
            filter,
            crmCustomers,
            loaded,
            hasFilter,
            total,
            totalFiltered,
            localCurrencySymbol,
            getArrowClass,
            GetCrmCustomers,
            CreateCustomer,
            ShowFilter,
            showAll,
            sotrBy,
            goToDetails,
            applyFilter,
            back,
            openCrmCustomerChatMessageModal,
            modalCrmCustomerChatMessage
        };
    }
});
