
import { defineComponent, ref, reactive } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import { BussinesClientByNewOrderObject, DateRange } from '@/models/Interfaces';
import { generalStore } from '@/store';
import { CrmCustomerListFilter } from '@/models/CrmCustomers';
import Multiselect from '@vueform/multiselect';
import Calendar from 'primevue/calendar';
import _ from 'lodash';
import useProduct from '@/modules/useProduct';
import { DateConstants } from '@/models/Constants';
import moment from 'moment';
import InputNumber from 'primevue/inputnumber';
import { ComparisonOperator, ToOptions } from '@/models/Enums';

export default defineComponent({
    components: {
        OverlayModal,
        Multiselect,
        Calendar,
        InputNumber
    },
    name: 'CrmCustomersFilterModal',
    async setup(props, { emit }) {
        const dateConstants = DateConstants;
        const isOpen = ref(false);
        const { dateRangeValidate } = useProduct();
        const bussinesClients = ref<BussinesClientByNewOrderObject[]>([]);
        const createDateRange = reactive(new DateRange());
        const lastOrderDateRange = reactive(new DateRange());
        const filter = ref(new CrmCustomerListFilter());
        const comparisonOperators = ToOptions(ComparisonOperator, 'comparison-operator.');
        async function open(filterObj: CrmCustomerListFilter) {
            filter.value = _.clone(filterObj);
            if (filter.value?.createDateFrom) {
                createDateRange.fromDate = moment(filter.value.createDateFrom, DateConstants.SERVER_NODATIME_INSTANT_FORMAT).toDate();
            } else {
                createDateRange.fromDate = null;
            }
            if (filter.value?.createDateTo) {
                createDateRange.toDate = moment(filter.value.createDateTo, DateConstants.SERVER_NODATIME_INSTANT_FORMAT).toDate();
            } else {
                createDateRange.toDate = null;
            }
            if (filter.value?.lastOrderDateFrom) {
                lastOrderDateRange.fromDate = moment(filter.value.lastOrderDateFrom, DateConstants.SERVER_NODATIME_INSTANT_FORMAT).toDate();
            } else {
                lastOrderDateRange.fromDate = null;
            }
            if (filter.value?.lastOrderDateTo) {
                lastOrderDateRange.toDate = moment(filter.value.lastOrderDateTo, DateConstants.SERVER_NODATIME_INSTANT_FORMAT).toDate();
            } else {
                lastOrderDateRange.toDate = null;
            }
            bussinesClients.value = await generalStore.dispatch('loadBusinessClients');
            isOpen.value = true;
        }
        function close() {
            isOpen.value = false;
        }

        function apply() {
            const toApplyFilter = _.clone(filter.value);
            if (createDateRange.fromDate) {
                toApplyFilter.createDateFrom = moment(createDateRange.fromDate).format(DateConstants.SERVER_NODATIME_INSTANT_FORMAT);
            }
            if (createDateRange.toDate) {
                toApplyFilter.createDateTo = moment(createDateRange.toDate).format(DateConstants.SERVER_NODATIME_INSTANT_FORMAT);
            }
            if (lastOrderDateRange.fromDate) {
                toApplyFilter.lastOrderDateFrom = moment(lastOrderDateRange.fromDate).format(DateConstants.SERVER_NODATIME_INSTANT_FORMAT);
            }
            if (lastOrderDateRange.toDate) {
                toApplyFilter.lastOrderDateTo = moment(lastOrderDateRange.toDate).format(DateConstants.SERVER_NODATIME_INSTANT_FORMAT);
            }
            emit('applyFilter', toApplyFilter);
            close();
        }
        const dateChanged = (dateRange: DateRange) => {
            if (!dateRange.fromDate || !dateRange.toDate) return;
            dateRangeValidate(dateRange);
        };

        return {
            isOpen,
            open,
            close,
            apply,
            bussinesClients,
            filter,
            createDateRange,
            lastOrderDateRange,
            dateChanged,
            dateConstants,
            comparisonOperators
        };
    }
});
